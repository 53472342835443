@import "../../assets/scss/variables.scss";

.content_align {
  margin-top: 10%;
}

.support_card_background {
  width: 100%;
  height: 250px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-decoration: none;
}

.img_container {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.support_link_header {
  font-size: 20px;
  color: $accentColor;
  text-decoration: none;
  text-align: center;
}

.support_link_description {
  width: 90%;
  margin: 0 auto;
  font-size: $font-16;
  text-align: center;
  color: $primaryColor;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .content_align {
    margin-top: 5px;
  }
}
