@import "../../assets/scss/variables.scss";

.user_avatar {
  width: 130px;
  height: 130px;
  margin: 0 auto;
}

.avatar_image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.user_name {
  font-size: 32px;
  font-weight: $medium;
  color: $primaryColor;
  text-align: left;
  margin: 0;
}

.user_email {
  font-size: $font-18;
  color: $secondaryColor;
  text-align: left;
  margin: 0;
}

@media screen and (max-width: 767px) {
  .user_name {
    text-align: center;
    padding-top: 20px;
  }
  .user_email {
    text-align: center;
  }
}
