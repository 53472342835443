@import "../../assets/scss/variables.scss";

.cart_items_order_summary_wrapper {
  position: fixed;
  left: 0;
  right: 0;
  background-color: $backgroundColor;
  box-shadow: -10px 0 20px 0 rgba(0, 0, 0, 0.15);
  z-index: 0;
  animation: slideOnWeb 0.5s ease forwards;
}

.label {
  font-size: $font-18;
  font-weight: $medium;
  color: $primaryColor;
  margin: 0;
}

.items_wrapper {
  max-height: 200px;
  overflow: auto;
}

.product_card {
  width: 100%;
  padding: 15px;
  background-color: $white;
  box-shadow: 0 0 5px 0 rgba($primaryColor, 0.15);
  border-radius: 8px;
}

@keyframes slideOnMobile {
  from {
    bottom: -300px;
  }
  to {
    bottom: 100px;
  }
}

@keyframes slideOnWeb {
  from {
    bottom: -300px;
  }
  to {
    bottom: 70px;
  }
}

@media screen and (max-width: 600px) {
  .items_container {
    bottom: 120px;
  }
  .cart_items_order_summary_wrapper {
    animation: slideOnMobile 0.5s ease forwards;
  }
}
