@import "../../assets/scss/variables.scss";

a {
  text-decoration: none;
  margin: 0;
}

.playground_height {
  height: calc(100vh - 60px);
}

.back_text {
  font-size: $font-12;
  color: #aaa;
  text-align: left;
  cursor: pointer;
  margin: 0;
}

.back_text:hover {
  color: $accentColor;
  transition: color 0.3s;
}

.back_icon {
  font-size: $font-12;
  color: #aaa !important;
  text-align: left;
  cursor: pointer;
  margin: 0;
  height: 15px !important;
  margin-right: 5px !important;
}

.back_icon:hover {
  color: $accentColor;
  transition: color 0.3s;
}

.width {
  width: 60%;
}

.product_list_without_summary_wrapper {
  height: 100%;
  overflow: auto;
}

.product_list_with_summary_wrapper {
  height: 90%;
  overflow: auto;
}

.product_img_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 30%;
  border-radius: 8px;

  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
  padding: 5px;
  position: relative !important;
}

.product_img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.product_name {
  font-size: $font-24;
  font-weight: $medium;
  color: $primaryColor;
  margin: 0;
}

.ordered_from {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  font-size: $font-12;
  color: #aaa;
  margin: 0;

  .bold {
    font-weight: $medium;
  }
}

.product_description {
  font-size: $font-14;
  color: $secondaryColor;
  margin: 0;
  color: $primaryColor;
}

.product_price {
  font-size: $font-16;
  font-weight: $medium;
  color: $primaryColor;
  margin: 0;
}

.order_type_image_container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba($accentColor, 0.15);
}

.order_type_value {
  font-size: $font-14;
  margin: 0;
  color: $accentColor;
}

.product_details_header {
  font-size: $font-18;
  color: $primaryColor;
  margin: 0;
}

.prodcut_details_key {
  flex-basis: 35%;
  font-size: $font-14;
  margin: 0;
  color: #aaa;
}

.prodcut_details_value {
  flex-basis: 65%;
  font-size: $font-14;
  font-weight: $medium;
  margin: 0;
  color: $primaryColor;
}

.add_to_cart_button {
  width: 120px;
  height: 35px;
  background-color: $white;
  border: 1px solid $accentColor;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-14;
  font-weight: $medium;
  color: $accentColor;
}

.add_to_cart_button:hover {
  background-color: $accentColor;
  color: $white;
  transition: all 0.3s;
}

.quantity_count_wrapper {
  width: 120px;
  height: 35px;
  background-color: $accentColor;
  border-radius: 36px;
  display: flex;
  align-items: center;
}

.quantity_count_wrapper>div {
  width: 40px;
  height: 35px;
}

.subtract_svg_color,
.add_svg_color {
  fill: $white;
}

.subtract_svg_wrapper {
  border-top-left-radius: 36px;
  border-bottom-left-radius: 36px;
  cursor: pointer;
}

.subtract_svg_wrapper:hover {
  background-color: rgba($white, $alpha: 0.75);
  transition: all 0.3s;

  .subtract_svg_color {
    fill: $accentColor;
  }
}

.add_svg_wrapper {
  border-top-right-radius: 36px;
  border-bottom-right-radius: 36px;
  cursor: pointer;
}

.add_svg_wrapper:hover {
  background-color: rgba($white, $alpha: 0.75);
  transition: all 0.3s;

  .add_svg_color {
    fill: $accentColor;
  }
}

.quantity_count {
  font-size: $font-14;
  font-weight: $medium;
  color: $white;
  text-align: center;
  margin: 0;
}

@media screen and (max-width: 767px) {
  .width {
    width: 100%;
  }
}

.next_icon_container {
  right: 10px;
}

.previous_icon_container {
  left: 10px;
}

.next_icon_container,
.previous_icon_container {
  position: absolute !important;
  cursor: pointer;
  padding: 3px 5px !important;
  border-radius: 50% !important;
}

.next_icon_container:hover,
.previous_icon_container:hover {
  background-color: #fff;
}

.next_icon {
  font-size: 15px;
  color: #aaa !important;
  height: 15px !important;
  transform: rotate(180deg);
}

.previous_icon {
  font-size: 15px;
  color: #aaa !important;
  height: 15px !important;
}