@import "../../assets/scss/variables.scss";

.playground_height {
  height: calc(100vh - 60px);
  overflow: auto;
}

.price_summary_card {
  width: 100%;
  background-color: $white;
  box-shadow: 0 0 10px 0 rgba($primaryColor, 0.15);
  border-radius: 10px;
}

.card_header {
  padding: 20px 25px;
  background-color: $white;
  border-bottom: 1px solid rgba($secondaryColor, 0.15);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .card_header_title {
    font-family: $firaSans;
    font-size: $font-18;
    font-weight: $medium;
    text-align: left;
    color: $primaryColor;
    margin: 0;
  }
}

.step_completed_card_header {
  padding: 20px 25px;
  background-color: rgba($success, 0.15);
  border: 1px solid $success;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .card_header_title {
    font-family: $firaSans;
    font-size: $font-18;
    font-weight: $medium;
    text-align: left;
    color: $primaryColor;
    margin: 0;
  }
}

.card_body {
  padding: 20px 25px;
  border-bottom: 1px solid rgba($secondaryColor, 0.15);

  .card_body_text {
    font-size: $font-14;
    text-align: left;
    color: $secondaryColor;
    margin: 0;
  }
}

.card_footer {
  padding: 20px 25px;

  .card_body_text {
    font-size: $font-14;
    text-align: left;
    color: $secondaryColor;
    margin: 0;
  }
}

.illustration_header {
  font-size: $font-20;
  font-family: $firaSans;
  text-align: center;
  color: $primaryColor;
  margin: 10px;
}

.illustration_body {
  font-size: $font-14;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  color: $secondaryColor;
}

.sub_total_text {
  font-size: $font-16;
  font-weight: $medium;
  text-align: left;
  color: $primaryColor;
  margin: 0;
}

.cart_label {
  font-family: $firaSans;
  font-size: $font-20;
  font-weight: $medium;
  text-align: left;
  color: $primaryColor;
  margin: 0;
}

.product_name {
  width: 70%;
  // display: -webkit-box;
  // -webkit-line-clamp: 1;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
  // text-overflow: ellipsis;
  word-break: break-all;
  font-size: $font-14;
  font-weight: $medium;
  color: $primaryColor;
  margin: 0;
}

.ordered_from {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  font-size: $font-12;
  color: $secondaryColor;
  margin: 0;

  .bold {
    font-weight: $medium;
  }
}

.address_wrapper {
  max-height: 200px;
  overflow: auto;
}

.address_type_label {
  font-size: $font-18;
  font-weight: $medium;
  color: $primaryColor;
  margin: 0;
}

.address_name_and_phone {
  font-size: $font-16;
  font-weight: $medium;
  text-align: left;
  color: $primaryColor;
  margin: 0;
}

.address_line_1 {
  font-size: $font-14;
  text-align: left;
  color: $primaryColor;
  margin: 0;
}

.address_line_2 {
  font-size: $font-14;
  text-align: left;
  color: $secondaryColor;
  margin: 0;
}

.add_address_wrapper {
  width: 160px;
  padding: 10px 15px;
  background-color: transparent;
  border-radius: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_address_wrapper:hover {
  background-color: $accentColor;
  transition: all 0.3s;

  .add_address_text {
    color: $white;
  }

  .add_svg_color {
    fill: $white;
  }
}

.add_address_text {
  font-size: $font-14;
  font-weight: $medium;
  color: $accentColor;
  margin: 0;
}

.add_svg_color {
  fill: $accentColor;
  // margin-bottom: 5px;
}

.juspay_card {
  height: 80%;
  position: fixed;
  z-index: 4;
  top: 10%;
  left: 30%;
  right: 30%;
  background: $white;
}

.remove_item {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  z-index: 2;
}

.product_disabled {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 85%;
  border-radius: 10px;
  background: rgba($error, $alpha: 0.14);
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled_text {
  margin: 0;
  font-size: 20px;
  width: 70%;
  text-align: center;
  color: $error;
}

.error {
  color: $error;
  font-size: 12px;
}

.provider {
  border-bottom: 1px solid #aaaaaa;
  margin-bottom: 10px;
}

.border_bottom_dotted {
  border-bottom: 1px dotted #aaaaaa;
}