@import "../../../assets/scss/variables.scss";

.toast_position {
  position: fixed;
  bottom: 50px;
  right: 50px;
  min-width: 500px;
  height: 80px;
  border-radius: 10px;
  background-color: $white;
  z-index: 10;
}

.toast_type_wrapper {
  flex-basis: 20%;
  height: 100%;
  background-color: red;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toast_message_wrapper {
  flex-basis: 70%;
}

.toast_message {
  width: 88%;
  height: 90%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: $font-14;
  font-weight: 500;
  color: $primaryColor;
  margin: 0;
  word-break: break-all;
  padding: 0 10px;
}

.toast_remove_wrapper {
  flex-basis: 10%;
}
